
import useUserStore from '@/store/modules/user'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie\
import { showDialog } from 'vant';
// i18n
// import { i18n } from '@/i18n'

const whiteList = ['/login']

const isGoingOn = () => {
  return new Promise((resolve) => {
    showDialog({
      message: waresInfo.value.OrderReturnTips,
      confirmButtonText: i18n.tc('确认'),
      cancelButtonText: i18n.tc('取消'),
      showCancelButton: true,
    }).then(() => {
      console.log('then');
      resolve(true)
    }).catch(() => {
      console.log('catch');
      resolve(false)
    });
  })
}


export function setupPermissions(router) {
  router.beforeEach(async (to, from, next) => {
    NProgress.start()
    if(from.name !== 'OrderConfirm') {
      next()
    }else{
      next()
    }
  //     if(from.name !== 'OrderConfirm' || to.name === from.name) {
  //   console.log(1);
  //   NProgress.done()
  //   next()
  //   return
  // }
  // if(!waresInfo.value.OrderReturnTips || to.name == 'OrderSuccess'){
  //   console.log(2);
  //   NProgress.done()
  //   next()
  // }else{
  //   const res = await isGoingOn()
  //   console.log(5);
  //   NProgress.done()
  //   if(res){
  //     console.log(6);
  //     next()
  //   }else{
  //     console.log(7);
  //     next(from.path)
  //   }
  // }
  })

  router.afterEach((to, from) => {
    document.title = to.meta.title
    NProgress.done()
  })
}
