<template>
  <div class="floating-bubble" id="floating-bubble">
    <div class="item" v-if="serviceObj.Facebook">
      <van-button :color="theme.pageBackground" round @click="onLink('https://www.m.me/' + serviceObj.Facebook)" >
        <svg-icon iconClass="messenger" :color="theme.vanPrimaryColor"></svg-icon>
      </van-button>
    </div> 
    <div class="item" v-if="serviceObj.Line">
      <van-button :color="theme.pageBackground" round @click="onLink('https://line.me/ti/p/' + serviceObj.Line)" >
        <svg-icon iconClass="line" :color="theme.vanPrimaryColor"></svg-icon>
      </van-button>
    </div> 
    <div class="item" v-if="serviceObj.WhatsApp">
      <van-button :color="theme.pageBackground" round @click="onLink('https://api.whatsapp.com/send?phone=' + serviceObj.WhatsApp)" >
        <svg-icon iconClass="whatsapp" :color="theme.vanPrimaryColor"></svg-icon>
      </van-button>
    </div> 
    <div class="item" v-if="serviceObj.Email">
      <van-button :color="theme.pageBackground" round @click="onEmail" >
        <svg-icon iconClass="mail" :color="theme.vanPrimaryColor"></svg-icon>
      </van-button>
    </div> 
    <div class="item" v-if="serviceObj.Phone">
      <van-button :color="theme.pageBackground" round @click="onTel" >
        <svg-icon iconClass="kefu" :color="theme.vanPrimaryColor"></svg-icon>
      </van-button>
    </div> 
    <div class="item" id="gettop">
      <van-back-top v-if="isShow" teleport="#gettop" />
    </div>
  </div>
</template>

<script setup>
// i18
import { useI18n } from 'vue-i18n'

import useAppStore from '@/store/modules/app';
const appStore = useAppStore();
const { servicePhone, serviceEmail, service } = storeToRefs(appStore)
const isShow = ref(false);

const theme = inject('theme')
onMounted(() => {
  isShow.value = true;
});

const serviceObj = computed(() => {
  try {
    return JSON.parse(service.value)
  } catch (error) {
    return {}
  }
})
const { locale, t } = useI18n()
const switchTheme = (value) => {
  appStore.switchTheme(value);

}

const switchLan = (value) => {
  console.log(value);
  appStore.changeLanguage(value);
  locale.value = value
}


const onTel = () => {
  window.location.href = `tel:${serviceObj.value.Phone}`
}
const onEmail = () => {
  window.location.href = `mailto:${serviceObj.value.Email}`
}
const onLink = (url) => {
  window.open(url)
}
</script>

<style lang="scss" scoped>
:deep(){
  .van-back-top{
    position: static;
    background-color: #fff;
    --van-back-top-text-color: #959595;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  }
}
.floating-bubble{
  position: fixed;
  right: 20px;
  bottom: 150px;
  --van-back-top-size: 80px;
  --van-back-top-icon-size: 40px;
  --van-button-default-height: 80px;
  z-index: 3;
  .item{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-top: 25px;
   
  }
  .van-button{
    padding: 0;
    width: 80px;
  }
  .svg-icon{
    font-size: 40px;
    color: var(--van-primary-color);
  }
}

</style>
