<template>
  <div class="layout" id="layout" :style="{...themeObject,...navStyle}" >
    <Header v-if="!route.meta?.hideHeader"/>
    <div class="container">
      <slot></slot>
    </div>
    
    <FloatingButton v-if="route.name !== 'NotFound' && !route.meta?.hideService"/>
  </div>
</template>

<script setup>
// components
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import FloatingButton from "./components/FloatingButton.vue";

//pinia
import useAppStore  from '@/store/modules/app.js'

// i18n
import { useI18n } from 'vue-i18n';

const appStore = useAppStore()
const { theme, favicon, language } = storeToRefs(appStore)

const { locale, t } = useI18n()
locale.value = language.value

const route = useRoute()

const props = defineProps({
  title: String,
});

provide('theme', theme)
//主题
const themeObject = useTheme(theme)


// navbar
const navStyle = computed(() => {
  return {
    // '--van-nav-bar-background':theme.value.navBackground,
    '--van-nav-bar-icon-color':theme.value.navColor,
    '--van-nav-bar-title-text-color':theme.value.navColor,
  }
})

appStore.changeFavicon(favicon.value)

</script>

<script>
export default {
  name: 'Layout',
  // inheritAttrs: false,
}


</script>

<style lang="scss" scoped>
.layout{
  height: 100%;
  overflow-y: auto;
  background: var(--page-background);
  color: var(--text-color-primary);
  display: flex;
  flex-direction: column;
}
.container{
  flex: 1;
}
:deep(.van-nav-bar){
  background: var(--nav-background);
}

</style>
