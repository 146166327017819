import pinia from '@/store'
import { createI18n } from "vue-i18n";
import useAppStore from '@/store/modules/app'
import en from './locales/en-US.json'
// const languages = require.context('./locales', false, /\.json$/)

const modules = import.meta.glob(`./locales/*.json`, { eager: true })  // */
const map = {}
for (let key in modules) {
    const name = key.split('/')[2].split('.')[0]
    map[name] = modules[key].default
}
const messages = {
  ...map,
  zh: {},
}



function getLanguage() {
  const value = localStorage.getItem('language')
  return value && isJson(value) ? JSON.parse(value) : false
}

export const i18n = createI18n({
  legacy: false,
  locale: getLanguage(),
  fallbackLocale: 'zh',
  silentTranslationWarn: true,
  missingWarn: false,
  silentFallbackWarn: true,
  fallbackWarn: false,
  messages,
})
export function setupI18n(app) {
  app.use(i18n);
  return i18n;
}


export function translate(message) {
  console.log(message);
  if (!message) {
    return ''
  }
  return (
    [getLanguage(), 'ymI18n', message].reduce(
      (o, k) => (o || {})[k],
      messages
    ) || message
  )
}