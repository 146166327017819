import { defineStore } from "pinia";
import { theme } from '@/utils/theme'
import { GetBaseEnum } from '@/api/common'
import { useFavicon } from '@vueuse/core';
import { BASEURL } from '@/config'

const getLocalStorage = (key) => {
  const value = localStorage.getItem(key)
  return value && isJson(value) ? JSON.parse(value) : false
}

const getSessionStorage = (key) => {
  const value = sessionStorage.getItem(key)
  return value && isJson(value) ? JSON.parse(value) : false
}
const { language = 'zh-CN' } = getLocalStorage('language')
const { currentTheme = THEME.默认 } = getLocalStorage('currentTheme')
const { favicon = '/favicon.ico' } = getLocalStorage('favicon')
const { servicePhone = '' } = getLocalStorage('servicePhone')
const { serviceEmail = '' } = getLocalStorage('serviceEmail')
// 客服
const { service = {} } = getLocalStorage('service')
const { timeData = {
  readTime: '',
  cartTime: '',
  orderTime: '',
} } = getSessionStorage('timeData')
const useAppStore = defineStore("app", {
  state: () => {
    return {
      themeList: theme,
      currentTheme: currentTheme,
      language: language,
      enums: [],
      favicon: favicon,
      servicePhone: servicePhone,
      serviceEmail: serviceEmail,
      timeData: timeData,
      service: service
    };
  },
  getters: {
    theme: (state) => {
      return state.themeList[state.currentTheme] || state.themeList[0];
    },
    getLanguage: (state) => state.language,
  },
  actions: {
    updateState(obj) {
      Object.getOwnPropertyNames(obj).forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this[key] = obj[key]
        localStorage.setItem(
          key,
          typeof obj[key] == 'string'
            ? `{"${key}":"${obj[key]}"}`
            : `{"${key}":${obj[key]}}`
        )
      })
    },
    switchTheme(currentTheme) {
      this.updateState({ currentTheme })
    },
    changeLanguage(language) {
      this.updateState({ language })
    },
    changeServicePhone(servicePhone) {
      this.updateState({ servicePhone })
    },
    changeServiceEmail(serviceEmail) {
      this.updateState({ serviceEmail })
    },
    changeFavicon(favicon) {
      this.updateState({ favicon: favicon })
      if(!favicon){
        useFavicon('/favicon.ico');
        return
      }
      if(favicon?.startsWith('http')){
        useFavicon(favicon);
      }else{
        useFavicon(BASEURL + favicon);
      }
      
    },
    getEnum() {
      return new Promise( async (resolve,reject) => {
        try {
          const { data } = await GetBaseEnum()
          this.enums = data
          resolve()
        } catch (error) {
          resolve({})
        }
      })
     
    },
    setTimeData(data) {
      this.timeData = data
    },
    setService(service) {
      this.updateState({ service })
    },
  },
});

export default useAppStore;
