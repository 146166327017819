<template>
  <header>
    <van-nav-bar 
      :title="$t(title || '')" 
      placeholder 
      fixed 
      z-index="2" 
      :left-arrow="hasHistory" 
      clickable
      @click-left="onRouterBack"
    />
  </header>
  
</template>

<script setup>
import { useI18n }  from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  title: String,
});

const route = useRoute();

const titleDetail = route.query && route.query.type == 'success' ? '订单提交成功' : '订单详情'

const title = computed(() => {
  if(route.name == 'OrderSuccess'){
    return titleDetail
  }
  if(route.name == 'Article'){
    const id = Number(route.params.id)
    return FOOTER_LINK[id]
  }
  return props.title || route.meta.title;
});




if(route.name == 'OrderSuccess'){
  useHead({
    title: titleDetail
  })
}else{
  useHead({
    title: title.value || ''
  })
}



useHead({
  title: t(title.value || '')
})

const history = ref(window.history);
const hasHistory = computed(() => {
  return history.value.length > 1;
});

const router = useRouter()
const onRouterBack = () => {
  router.back()
}
</script>

<style lang="scss" scoped></style>
