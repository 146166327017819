import axios from 'axios'
import router from '@/router/index'
import useUserStore from '@/store/modules/user'
import { isArray } from '@/utils/validate'
import { showToast, showSuccessToast, showFailToast } from 'vant';

const successCode = [200, 0, '200', '0', true ]
// 操作正常Code数组
const codeVerificationArray = isArray(successCode)
  ? [...successCode]
  : [...[successCode]]

const CODE_MESSAGE = {
  200: '服务器成功返回请求数据',
  201: '新建或修改数据成功',
  202: '一个请求已经进入后台排队(异步任务)',
  204: '删除数据成功',
  400: '发出信息有误',
  401: '用户没有权限(令牌失效、用户名、密码错误、登录过期)',
  402: '令牌过期',
  403: '用户得到授权，但是访问是被禁止的',
  404: '访问资源不存在',
  406: '请求格式不可得',
  410: '请求资源被永久删除，且不会被看到',
  500: '服务器发生错误',
  502: '网关错误',
  503: '服务不可用，服务器暂时过载或维护',
  504: '网关超时',
}

// create an axios instance
const service = axios.create({
  baseURL: import.meta.env.MODE === 'development' ? '/API' : window.__PRODUCTION__CONF__.VITE_GLOB_APP_BASEURL + '/API',
  withCredentials: false, // send cookies when cross-domain requests
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
  timeout: 600000,

})

// request interceptor
service.interceptors.request.use(
  config => {
    const userStore = useUserStore()
    if (userStore.token) {
      config.headers.Authorization = 'Bearer ' + userStore.token
    }
    config.hideToast = config.hideToast
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    const config = response.config
    if (!res) return
    let code = res && res['success'] ? res['success'] : res.status
    if (code !== true) {
      // message.error(res.msg || 'error')
      console.log(res);
      console.log(!config.hideToast);
      !config.hideToast && showToast(res.msg || 'error');

      // if (code === 401 && !location.href.includes('/login')) {
      //   const userStore = useUserStore()
      //   userStore.removeUser()
      //   router.push({ path: '/login' })
      // }

      return Promise.reject(res.msg || 'error')
    } else {
      return res
    }
  },
  error => {
    console.log(error);
    const err = error.response.data
    const config = error.response.config
    // if (err.code === 401 && !location.href.includes('/login')) {
    //   const userStore = useUserStore()
    //   userStore.removeUser()
    //   router.push({ path: '/login' })
    // }
    console.log('err' + error) // for debug
    // message.error(err.msg)
    !config.hideToast && showToast(CODE_MESSAGE[error.response.status]);
    return Promise.reject(err)
  }
)

export default service