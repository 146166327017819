<script setup>
import Layout from '@/layout/index.vue'
import useAppStore from '@/store/modules/app'
const { getEnum } = useAppStore()

const route = useRoute()
// rem
const setRem = () => {
  var html = document.documentElement;
  var wW = html.clientWidth;
  wW = Math.min(wW, 750);
  // 设计稿分辨率1440
  var whdef = 100 / 750;
  var rem = wW * whdef;
  document.documentElement.style.fontSize = rem + "px";
};
onBeforeMount(() => {
  getEnum()
  setRem();
  // 改变窗口大小时重新设置 rem
  window.onresize = () => {
    setRem();
  };
});
</script>

<template>
  <Layout>
    <router-view v-slot="{ Component}">
      <keep-alive >
        <Component :is="Component" v-if="route.meta.keepAlive"/>
      </keep-alive>
      <Component :is="Component" v-if="!route.meta.keepAlive"/>
    </router-view>
   
  </Layout>
</template>

<style lang="scss">
@import "@/assets/font/font.css";
html,
body {
  width: 100%;
  height: 100%;
}
</style>
