/**
 * @link hooks/useTheme
 * @description 却换主题
 */
export function useTheme(themeVars) {
  // const themeObject = computed(() => {
  //   return {
  //     "--page-background": theme.value.pageBackground,
  //     "--page-background-secondary": theme.value.pageBackgroundSecondary,
  //     "--nav-bar-background": `url(${theme.value.navBackground}) center bottom / cover`,
  //     "--nav-bar-title-text-color": theme.value.navColor,
  //     "--ban-text-color": theme.value.banTextColor,

  //     "--promotion-background": theme.value.gradientPrimary,
  //     "--promotion-right-background": theme.value.promotionRightBackground,
  //     "--promotion-left-text-color": theme.value.promotionLeftTextColor,
  //     "--promotion-left-title-color": theme.value.promotionLeftTitleColor,
  //     "--promotion-left-description-color": theme.value.promotionLeftDescriptionColor,
  //     "--promotion-right-title-color": theme.value.promotionRightTitleColor,
  //     "--promotion-right-description-color": theme.value.promotionRightDescriptionColor,
  //     "--text-color-primary": theme.value.textColorPrimary,

  //     "--goods-price-color": theme.value.goodsPriceColor,
  //     "--goods-old-price-color": theme.value.goodsOldPriceColor,

  //     "--goods-tag-background": theme.value.goodsTagBackground,
  //     "--goods-tag-text-color": theme.value.goodsTagTextColor,

  //     "--guarantee-background": theme.value.guaranteeBackground,
  //     "--guarantee-border": theme.value.guaranteeBorder,
  //     "--guarantee-label-color": theme.value.guaranteeLabelColor,
  //     "--guarantee-tag-color": theme.value.guaranteeTagColor,
  //     "--guarantee-tag-background": theme.value.guaranteeTagBackground,

  //     "--user-background": theme.value.gradientSecondary,
  //     "--user-background-inner": theme.value.userBackgroundInner,
  //     "--user-border": theme.value.userBorder,
  //     "--user-avatar-title-color": theme.value.userAvatarTitleColor,
  //     "--user-title-color": theme.value.userTitleColor,

  //     "--user-count-title-color": theme.value.userCountTitleColor,
  //     "--user-count-color": theme.value.userCountColor,
      
  //     "--user-count-color": theme.value.userCountColor,

  //     '--van-button-default-color': theme.value.vanButtonDefaultColor,
  //     '--van-button-default-background': theme.value.vanButtonDefaultBackground,
  //     '--van-button-default-border-color': theme.value.vanButtonDefaultBorderColor,

  //   };
  // });
  // return themeObject
  function kebabCase(str) {
    str = str.replace(str.charAt(0), str.charAt(0).toLocaleLowerCase())
    return str.replace(/([a-z])([A-Z])/g, (_, p1, p2) => `${p1}-${p2.toLowerCase()}`)
  }
  // const cssVars = {}

  const themeObject = computed(() => {
    const cssVars = {}
    Object.keys(themeVars.value).forEach((key) => {
      cssVars[`--${kebabCase(key)}`] = themeVars.value[key]
    })
    return cssVars
  })
  return themeObject
}
