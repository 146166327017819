import { BASEURL } from '@/config'
export const theme = [
  {
    //#region 基础
    title: "默认",
    value: 0,
    pageBackground: "#F4F4F4",
    pageBackgroundSecondary: "#ffffff",
    navColor: "#fff",
    navBackground: `url(${BASEURL}/images/nav_bar_bg.jpg) center bottom / cover`,
    banTextColor: "#fff",

    // 文字颜色
    textColorPrimary: "#1B1B1B",
    textColorSecondary: "#7E7E7E",
    //文字亮色
    textColorBright: '#fff',
    // 渐变色
    gradientPrimary: 'radial-gradient( circle at 0% 0%, #FF621E 0%, #F11F46 100%), radial-gradient( circle at 0% 0%, #FF621E 0%, #FF2D34 66%, #FF2D34 99%)',
    gradientSecondary: 'radial-gradient(circle at 0% 0%, #FF621E 0%, #F11F46 100%)',

    // 主要按钮颜色
    vanButtonPrimaryBackground: 'radial-gradient(circle at 0% 0%, rgb(255, 98, 30) 0%, rgb(241, 31, 70) 100%)',
    vanButtonPrimaryColor: '#fff',
    vanButtonPrimaryBorderColor: 'radial-gradient(circle at 0% 0%, rgb(255, 98, 30) 0%, rgb(241, 31, 70) 100%)',

    // 默认按钮颜色
    vanButtonDefaultBackground: '#F4F4F4',
    vanButtonDefaultColor: '#545454',
    vanButtonDefaultBorderColor: '#F4F4F4',

    // tab 切换
    vanTabTextColor: '#7B7B7B',
    vanTabActiveTextColor: '#1b1b1b',
    vanTabsBottomBarColor: '#FF494A',

    //pagination
    paginationTextColor: '#787874',
    paginationTextColorActive: '#FF494A',
    paginationActiveBackground: '#FFEFED',


    // vant
    vanPrimaryColor: '#FF3630', 
    vanFieldLabelColor: '#7E7E7E',

    borderColorPrimary: '#EDECEE',
    boxShadowDefault: '0px 0px 24px 0px rgba(33, 33, 33, 0.08)',


    //skeleton
    vanSkeletonParagraphBackground: '#e5dfdf',
    vanSkeletonAvatarBackground: '#e5dfdf',
    
    // 单选框
    radioBackground: '#F4F4F4',
    radioBorder: '#F4F4F4',
    radioTextColor: '#1b1b1b',
    radioActiveBackground: '#FFEBE9',
    radioActiveBorder: '#FF3630',
    radioActiveTextColor: '#FF3630',

    // input
    inputColor: '#1b1b1b',
    inputPlaceholderColor: '#CECECE',
    inputBackground: '#F6F6F6',
    inputBorder: '#F6F6F6',
    inputBorderFocus: '#CECECE',


    
     //picker
     vanPickerMaskColor: "linear-gradient(180deg, rgba(255, 255, 255, .9), rgba(255, 255, 255, .4)), linear-gradient(0deg, rgba(255, 255, 255, .9), rgba(255, 255, 255, .4))",
    //#endregion

    
    //#region 产品详情促销
    promotionRightBackground: "#FBE5E4",
    promotionLeftTitleColor: "#ffffff",
    promotionLeftDescriptionColor: "#FFEAE8",
    promotionRightTitleColor: "#FF3630",
    promotionRightDescriptionColor: "#FF3630",
    //#endregion
   
    //#region 价格颜色
    priceColor: "#FF3630",
    oldPriceColor: "#787874",
    //#endregion

    //#region 产品详情标签
    goodsTagBackground: "#FFEBE9",
    goodsTagTextColor: "#FF3630",
    //#endregion
    

    //#region 产品服务保障
    guaranteeBackground: "#FCF3EA",
    guaranteeBorder: "#FCF3EA",
    guaranteeLabelColor: "#E3732D",
    guaranteeTagColor: "#E3732D",
    guaranteeTagBorder: "#E3732D",
    guaranteeTagBackground: "rgba(255, 239, 229, 0)",
    //#endregion

    

    //#region 产品详情拼单列表
    userBackgroundInner: "linear-gradient(90deg, #ffffff 0%, #fff1f0 100%)",
    userBorder: "#F63546",
    userTitleColor: "#ffffff",
    userAvatarTitleColor: '#1b1b1b',
    userCountTitleColor: "#FF3630",
    userCountColor: '#ADABB3',
    userBtnColor: "radial-gradient( circle at 0% 0%, #FF621E 0%, #F11F46 100%)",
    userBtnTextColor: '#ffffff',
    userBtnTipBackground: '#FBF194',
    userBtnTipColor: '#FF3630',
    //#endregion

  

    //#region 评论
    commentLeftColor: 'radial-gradient(circle at 0% 0%, rgb(255, 98, 30) 0%, rgb(241, 31, 70) 100%)',
    commentTitleColor: '#1b1b1b',
    commentBorderColor: '#EDECEE',
    commentTimeColor: '#ADABB3',
    commentItemTitleColor: '#787874',
    //#endregion

    //#region footer
    footerBackground: '#F6F6F6',
    footerLinkColor: '#7E7E7E',
    footerLinkColorActive: '#ff494a',
    //#endregion


    //#region  产品详情左边按钮
    vanButtonSecondaryBackground: 'radial-gradient( circle at 0% 0%, #FFC501 0%, #FFAD01 27%, #FF9A02 99%)',
    vanButtonSecondaryTextColor: '#fff',
    //#endregion

    //#region 下单页面产品信息标签
    waresTagColor: '#E98F57',
    //#endregion

    //#region 门店
    storeNameColor: "#555555",
    storeColorActive: "#FF3630",
    storeAddressColor: "#7B7B7B",
    storeTagBackground: "#F6F6F6",
    storeActiveBackground: "#FFEBE9"
    //#endregion
    
  },
  {
    //#region 基础
    title: "黑金",
    value: 1,
    pageBackground: "#252628",
    pageBackgroundSecondary: "#2c2e2f",
    navColor: "#f6e5c2",
    navBackground:`url(${BASEURL}/images/nav_bar_bg02.jpg) center bottom / cover`,
    banTextColor: "#f6e5c2",
    // 文字颜色
    textColorPrimary: "#ffffff",
    textColorSecondary: "#bdbdbd",
    //文字亮色
    textColorBright: '#fff',
    // 渐变色
    gradientPrimary: 'radial-gradient( circle at 0% 0%,#DDB97E 0%, #E4C896 66%, #F1DAB2 99%)',
    gradientSecondary: 'radial-gradient( circle at 0% 0%, #DDB97E 0%, #F1DAB2 100%)',

    // 按钮颜色
    vanButtonPrimaryBackground: 'radial-gradient( circle at 0% 0%, #DDB97E 0%, #E4C896 66%, #F1DAB2 99%)',
    vanButtonPrimaryColor: '#1D0A05',
    vanButtonPrimaryBorderColor: 'radial-gradient( circle at 0% 0%, #DDB97E 0%, #E4C896 66%, #F1DAB2 99%)',
    // 默认按钮颜色
    vanButtonDefaultBackground: '#F4F4F4',
    vanButtonDefaultColor: '#545454',
    vanButtonDefaultBorderColor: '#F4F4F4',

    // tab 切换
    vanTabTextColor: '#ffffff',
    vanTabActiveTextColor: '#E4C896',
    vanTabsBottomBarColor: 'radial-gradient( circle at 0% 0%, #DDB97E 0%, #E4C896 66%, #F1DAB2 99%)',

    //vant
    vanPrimaryColor: '#F6E5C2', 
    vanFieldLabelColor: '#fff',

    borderColorPrimary: '#3C3D3E',
    boxShadowDefault: '0px 0px 24px 0px rgba(245, 245, 245, 0.08)',

    //skeleton
    vanSkeletonParagraphBackground: '#505155',
    vanSkeletonAvatarBackground: '#505155',

    // 单选框
    radioBackground: '#4c5152',
    radioBorder: '#4c5152',
    radioTextColor: '#ffffff',
    radioActiveBackground: '#f6e5c2',
    radioActiveBorder: '#F6E5C2',
    radioActiveTextColor: '#3c1e0e',

    // input
    inputColor: '#ffffff',
    inputPlaceholderColor: '#CECECE',
    inputBackground: '#4c5152',
    inputBorder: '#4c5152',
    inputBorderFocus: '#CECECE',


    vanPopupBackground: "#2c2e2f",

    //picker
    vanPickerMaskColor: "linear-gradient(180deg, rgba(44,46,47,.9), rgba(44,46,47, .4)), linear-gradient(0deg, rgba(44,46,47, .9), rgba(44,46,47, .4))",
    //#endregion
    

    //#region  产品详情促销
    promotionRightBackground: "#222222",
    promotionLeftTitleColor: "#1D0A05",
    promotionLeftDescriptionColor: "#58452E",
    promotionRightTitleColor: "#F6E5C2",
    promotionRightDescriptionColor: "#F6E5C2",
    //#endregion
    
    //#region 产品详情产品信息
    priceColor: "#f6e5c2",
    oldPriceColor: "#acacac",
    //#endregion

    //#region 产品详情标签
    goodsTagBackground: "#efd8af",
    goodsTagTextColor: "#1d0a05",
    //#endregion

    //#region 产品服务保障
    guaranteeBackground: "#252628",
    guaranteeBorder: "#252628",
    guaranteeLabelColor: "#dfdfe0",
    guaranteeTagColor: "#f6e5c2",
    guaranteeTagBorder: "#f6e5c2",
    guaranteeTagBackground: "rgba(255, 239, 229, 0.102)",
    //#endregion

    //#region 产品详情拼单列表
    userBackgroundInner: "#fff",
    userBorder: "#F6E5C2",
    userTitleColor: "#ffffff",
    userAvatarTitleColor: '#1b1b1b',
    userCountTitleColor: "#A25D03",
    userCountColor: '#ADABB3',

    userBtnTipBackground: '#FBF194',
    userBtnTipColor: '#FF3630',
    //#endregion

 

    //#region 评论
    commentLeftColor: 'radial-gradient( circle at 0% 0%, #FFF8EA 0%, #FBE5B7 69%, #FBE5B7 97%)',
    commentTitleColor: '#F6E5C2',
    commentBorderColor: '#3C3D3E',
    commentTimeColor: '#78777C',
    commentItemTitleColor: '#C5C5C5',
    //#endregion

    

    //#region footer
    footerBackground: '#F6F6F6',
    footerLinkColor: '#7E7E7E',
    footerLinkColorActive: '#E4C896',
    //#endregion

    //#region pagination
    paginationTextColor: '#C5C5C5',
    paginationTextColorActive: '#E4C896',
    paginationActiveBackground: '#1F1F21',
    //#endregion

 

    //#region 产品详情左边按钮
    vanButtonSecondaryBackground: '#f2d9b0',
    vanButtonSecondaryTextColor: '#000000',
    //#endregion

    //#region 门店
    storeNameColor: "#ffffff",
    storeColorActive: "#F6E5C2",
    storeAddressColor: "#eee",
    storeTagBackground: "#1c1d1e",
    storeActiveBackground: "#FFEBE9"
    //#endregion

  },


  {
    //#region 基础 金色1
    title: "金色1",
    value: 2,
    pageBackground: "#C3A365",
    pageBackgroundSecondary: "#a47d30",
    navColor: "#1B1B1B",
    navBackground:`url(${BASEURL}/images/nav_bar_bg03.jpg) center bottom / cover`,
    banTextColor: "#f6e5c2",
    // 文字颜色
    textColorPrimary: "#ffffff",
    textColorSecondary: "#bdbdbd",
    //文字亮色
    textColorBright: '#1B1B1B',
    // 渐变色
    gradientPrimary: 'radial-gradient( circle at 0% 0%, #F5E0BE 0%,#FFF1D9 66%, #FFF1D9 100%)',
    gradientSecondary: 'radial-gradient( circle at 0% 0%, #F5E0BE 0%,#F4E7C7 66%, #F4E7C7 100%)',
    // 按钮颜色
    vanButtonPrimaryBackground: 'radial-gradient( circle at 0% 0%, #F5E0BE 0%, #E4C896 66%, #FFF1D9 100%)',
    vanButtonPrimaryColor: '#1D0A05',
    vanButtonPrimaryBorderColor: 'radial-gradient( circle at 0% 0%, #F5E0BE 0%, #E4C896 66%, #FFF1D9 100%)',

    // 默认按钮颜色
    vanButtonDefaultBackground: '#F4F4F4',
    vanButtonDefaultColor: '#545454',
    vanButtonDefaultBorderColor: '#F4F4F4',
    // tab 切换
    vanTabTextColor: '#000000',
    vanTabActiveTextColor: '#ffffff',
    vanTabsBottomBarColor: '#ffffff',

    // footer
    footerBackground: '#F6F6F6',
    footerLinkColor: '#7E7E7E',
    footerLinkColorActive: '#E4C896',

    //pagination
    paginationTextColor: '#f1ece3',
    paginationTextColorActive: '#684500',
    paginationActiveBackground: 'rgba(244, 231, 199, 1)',

    //vant
    vanPrimaryColor: '#E4C896', 
    vanFieldLabelColor: '#fff',

    borderColorPrimary: '#C8AB6F',
    boxShadowDefault: '0px 0px 24px 0px rgba(245, 245, 245, 0.08)',

    //skeleton
    vanSkeletonParagraphBackground: '#f2f3f5',
    vanSkeletonAvatarBackground: '#f2f3f5',


    // 单选框
    radioBackground: '#f1eed0',
    radioBorder: '#f1eed0',
    radioTextColor: '#1b1b1b',
    radioActiveBackground: '#c9ac46',
    radioActiveBorder: '#c9ac46',
    radioActiveTextColor: '#ffffff',

    // input
    inputColor: '#ffffff',
    inputPlaceholderColor: '#f9f8ed',
    inputBackground: '#b99839',
    inputBorder: '#b99839',
    inputBorderFocus: '#CECECE',
    


    vanPopupBackground: "#a47d30",
    //picker
    vanPickerMaskColor: "linear-gradient(180deg, rgba(164, 125, 48, .9), rgba(164, 125, 48, .4)), linear-gradient(0deg, rgba(164, 125, 48, .9), rgba(164, 125, 48, .4))",

    //#endregion
    


    //#region 
    promotionRightBackground: "#fff",
    promotionLeftTitleColor: "#1D0A05",
    promotionLeftDescriptionColor: "#625448",
    promotionRightTitleColor: "#A47D30",
    promotionRightDescriptionColor: "#A47D30",
    //#endregion

    
    //#region 产品详情产品信息
    priceColor: "#ffffff",
    oldPriceColor: "#684500",
    //#endregion

    //#region 产品详情标签
    goodsTagBackground: "#F4E7C7",
    goodsTagTextColor: "#1d0a05",
    //#endregion

    //#region 产品服务保障
    guaranteeBackground: "#a47d30",
    guaranteeBorder: "#C8AB6F",
    guaranteeLabelColor: "#ffffff",

    guaranteeTagColor: "#FFF5E0",
    guaranteeTagBorder: "#F6E5C2",
    guaranteeTagBackground: "#a47d30",
    //#endregion

    //#region 产品详情拼单列表
    userBackgroundInner: "#fff",
    userBorder: "#F6E5C2",
    userTitleColor: "#3F3E3E",
    userAvatarTitleColor: '#1b1b1b',
    userCountTitleColor: "#E9322D",
    userCountColor: '#ADABB3',

    userBtnTipBackground: 'radial-gradient( circle at 0% 0%, #FFC501 0%, #FF9920 99%)',
    userBtnTipColor: '#ffffff',
    //#endregion

  
    //#region 评论
    commentLeftColor: 'radial-gradient( circle at 0% 0%, #FFF8EA 0%, #FBE5B7 69%, #F4E7C7 97%)',
    commentTitleColor: '#ffffff',
    commentBorderColor: '#C8AB6F',

    commentTimeColor: '#DCCDB3',
    commentItemTitleColor: '#F2EDE4',
    //#endregion

    //#region 产品详情左边按钮
    vanButtonSecondaryBackground: 'radial-gradient( circle at 0% 0%, #FFC501 0%, #FF9920 99%)',
    vanButtonSecondaryTextColor: '#ffffff',
    //#endregion
    


    //#region 门店
    storeNameColor: "#ffffff",
    storeColorActive: "#E4C896",
    storeAddressColor: "#eee",
    storeTagBackground: "#8a6929",
    storeActiveBackground: "#FFEBE9"
    //#endregion
  },
  {
    //#region 
    title: "金色2",
    value: 3,
    pageBackground: "#F7E6CA",
    pageBackgroundSecondary: "#fff1d9",
    navColor: "#1B1B1B",
    navBackground:`url(${BASEURL}/images/nav_bar_bg04.jpg) center bottom / cover`,

    banTextColor: "#F6E5C2",
    // 文字颜色
    textColorPrimary: "#1B1B1B",
    textColorSecondary: "#7E7E7E",
    //文字亮色
    textColorBright: '#fff',
    // 渐变色
    gradientPrimary: 'radial-gradient( circle at 0% 0%, #FFC501 0%, #FF9920 100%)',
    gradientSecondary: 'radial-gradient( circle at 0% 0%, #FFC501 0%, #FF9920 100%)',


    // 按钮颜色
    vanButtonPrimaryBackground: 'radial-gradient( circle at 0% 0%, #F5E0BE 0%, #E4C896 66%, #FFF1D9 100%)',
    vanButtonPrimaryColor: '#1D0A05',
    vanButtonPrimaryBorderColor: 'radial-gradient( circle at 0% 0%, #F5E0BE 0%, #E4C896 66%, #FFF1D9 100%)',
    // 默认按钮颜色
    vanButtonDefaultBackground: '#F4F4F4',
    vanButtonDefaultColor: '#545454',
    vanButtonDefaultBorderColor: '#F4F4F4',
    // tab 切换
    vanTabTextColor: '#5B5854',
    vanTabActiveTextColor: '#1B1B1B',
    vanTabsBottomBarColor: '#96621E',

    // footer
    footerBackground: '#F6F6F6',
    footerLinkColor: '#7E7E7E',
    footerLinkColorActive: '#E4C896',

    //pagination
    paginationTextColor: '#5B5854',
    paginationTextColorActive: '#684500',
    paginationActiveBackground: '#F4E7C7',

    // vant
    vanPrimaryColor: '#96621E', 
    vanFieldLabelColor: '#1b1b1b',

    borderColorPrimary: '#E1CBA0',
    boxShadowDefault: '0px 0px 24px 0px rgba(33, 33, 33, 0.08)',
    //skeleton
    vanSkeletonParagraphBackground: '#f2f3f5',
    vanSkeletonAvatarBackground: '#f2f3f5',
    // 单选框
    radioBackground: '#f7efdd',
    radioBorder: '#f7efdd',
    radioTextColor: '#1b1b1b',
    radioActiveBackground: '#eeddba',
    radioActiveBorder: '#d6a561',
    radioActiveTextColor: '#1b1b1b',
 
    // input
    inputColor: '#1b1b1b',
    inputPlaceholderColor: '#cd8d42',
    inputBackground: '#eeddba',
    inputBorder: '#eeddba',
    inputBorderFocus: '#CECECE',
    
 
    vanPopupBackground: "#fff1d9",  

    //picker
    vanPickerMaskColor: "linear-gradient(180deg, rgba(255, 241, 217, .9), rgba(255, 241, 217, .4)), linear-gradient(0deg, rgba(255, 241, 217, .9), rgba(255, 241, 217, .4))",

    //#endregion
    
    //#region  产品详情促销
    promotionRightBackground: "#fff",
    promotionLeftTitleColor: "#1D0A05",
    promotionLeftDescriptionColor: "#625448",
    promotionRightTitleColor: "#96621E",
    promotionRightDescriptionColor: "#96621E",
    //#endregion

    //#region 产品详情产品信息
    priceColor: "#FF0505",
    oldPriceColor: "#684500",
    //#endregion

    //#region 产品详情标签
    goodsTagBackground: "#F4E7C7",
    goodsTagTextColor: "#684500",
    //#endregion
    
    //#region 产品服务保障
    guaranteeBackground: "#fff7ea",
    guaranteeBorder: "#fff7ea",
    guaranteeLabelColor: "#A47D30",
    guaranteeTagColor: "#1B1B1B",
    guaranteeTagBorder: "#A47D30",
    guaranteeTagBackground: "#fff7ea",
    //#endregion

    //#region 产品详情拼单列表
    userBackgroundInner: "#fff",
    userBorder: "#F6E5C2",
    userTitleColor: "#ffffff",
    userAvatarTitleColor: '#1b1b1b',
    userCountTitleColor: "#E9322D",
    userCountColor: '#ADABB3',

    userBtnTipBackground: 'radial-gradient( circle at 0% 0%, #FFC501 0%, #FF9920 99%)',
    userBtnTipColor: '#ffffff',
    //#endregion


    //#region 评论
    commentLeftColor: 'radial-gradient( circle at 0% 0%, #FFC401 0%,  #FFC401 100%)',
    commentTitleColor: '#1B1B1B',
    commentBorderColor: '#E1CBA0',

    commentTimeColor: '#9E9689',
    commentItemTitleColor: '#5B5854',
    //#endregion

  
    //#region  产品详情左边按钮
    vanButtonSecondaryBackground: 'radial-gradient( circle at 0% 0%, #FFC501 0%, #FF9920 99%)',
    vanButtonSecondaryTextColor: '#ffffff',
    //#endregion


     //#region 门店
     storeNameColor: "#555555",
     storeColorActive: "#96621E",
     storeAddressColor: "#7B7B7B",
     storeTagBackground: "#e4d5ba",
     storeActiveBackground: "#96621E"
     //#endregion
  },
];
