import Cookies from 'js-cookie'

const tokenKey = 'token'

// 获取token
export function getToken() {
  return Cookies.get(tokenKey)
}

// 设置token
export function setToken(token, autoLogin) {
  // 自动登录token有效期7天
  return Cookies.set(tokenKey, token, { expires: autoLogin ? 7 : '' })
}
// 移除token
export function removeToken() {
  return Cookies.remove(tokenKey)
}

