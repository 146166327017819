import request from '@/utils/request'





export async function GetDataById(params) {
  return request({
    url: '/Common/GetDataById',
    method: 'GET',
    params,
  })
}


export async function GetDataList(params) {
  return request({
    url: '/Common/GetDataList',
    method: 'GET',
    params,
  })
}

export async function GetBaseEnum() {
  return request({
    url: '/Common/GetBaseEnum',
    method: 'GET',
  })
}



