import { createApp } from 'vue'
import App from './App.vue'
import { setupRouter } from '@/router'
import { setupI18n } from '@/i18n'
import { createHead } from 'unhead'

// 引入pinia
import { createPinia } from 'pinia'

// svg 图标
import 'virtual:svg-icons-register';


import { showToast } from 'vant';
import 'vant/es/toast/style';
import 'vant/lib/index.css';

// Dialog
import { showDialog } from 'vant';
import 'vant/es/dialog/style';

// Notify
import { showNotify } from 'vant';
import 'vant/es/notify/style';

// ImagePreview
import { showImagePreview } from 'vant';
import 'vant/es/image-preview/style';

import '@/styles/reset.scss'
import 'normalize.css'

const head = createHead()
//挂载pinia
const app = createApp(App)

app.use(createPinia())
setupI18n(app)
setupRouter(app).isReady().then(() => app.mount('#app'))

