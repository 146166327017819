import { defineStore } from "pinia";
import { getToken, setToken, removeToken } from '@/utils/auth.js'
// import { Login, GetUserInfo } from '@/api/user'
import { resetRouter } from '@/router'
// import { GetEnumClass } from "@/api/tool";
const useUserStore = defineStore('user', {
  state: () => {
    return {
      userInfo: {
        UserName: '',
        Id: '',
        Avatar: ''
      },
      token: getToken(),
      enumClass: null
    }
  },
  getters: {
  },
  actions: {
    setToken(token) {
      if (token) {
        console.log(token, 'token');
        //存储到vuex中
        this.token = token;
        //token持久化存储
        setToken(token);
      }
    },
    removeUser() {
      this.userInfo = {
        UserName: '',
      }
      this.token = '';
      removeToken();
    },
    saveUserInfo(payload) {
      this.userInfo = payload
    },


    // 登录
    login(userInfo) {
      return new Promise((resolve, reject) => {
        Login(userInfo).then(response => {
          const { data } = response
          console.log(data.token.token);
          this.setToken(data.token.token)
          resolve(response.msg)
        }).catch(error => {
          console.log(error);
          reject(error)
        })
      })
    },

    // 用户登录后获取用户信息
    getUserInfo() {
      return new Promise((resolve, reject) => {
        GetUserInfo().then(response => {
          if (response.code === 401) {
            this.removeUser('')
            resolve(response.data)
          } else {
            this.saveUserInfo(response.data)
            this.getEnumClass()
            resolve(response.data)


          }
        }).catch(error => {
          reject(error)
        })

      })
    },
    // 获取所有枚举
    getEnumClass() {
      return new Promise((resolve, reject) => {
        GetEnumClass().then(res => {
          const { data } = res
          this.enumClass = data
          resolve(data)
        }).catch(e => {
          reject(e)
        })
      })
    },
    /**
    * @description 登录拦截放行时，设置虚拟角色
    */
    setVirtualRoles() {
      const aclStore = useAclStore()
      aclStore.setFull(true)
    },
    // 退出登录
    logout() {
      return new Promise((resolve, reject) => {
        this.removeUser('')
        resolve()
      })
    },

    // remove token
    resetToken() {
      return new Promise(async resolve => {
        await resetRouter()
        this.setToken('')
        removeToken()
        resolve()
      })
    },
  }
})

export default useUserStore