import type { RouteRecordName, RouteRecordRaw } from 'vue-router'
import type { RouteRecord } from '/#/router'
import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import { setupPermissions } from './permission'


export const constantRoutes: RouteRecord[] = [
  {
    path: "/:id",
    name: 'Index',
    component: () => import("@/views/Index/index.vue"),
    meta: { 
      title: "产品详情",
      hideHeader: true,
      keepAlive: true,
    },
  },
  {
    path: "/preview",
    name: 'Preview',
    component: () => import("@/views/Index/preview.vue"),
    meta: { 
      title: "产品详情预览",
      hideHeader: true,
    },
  },
  {
    path: "/order",
    name: 'Order',
    redirect: "/order/order-confirm",
    meta: { 
      title: "订单",
    },
    children: [
      {
        path: "order-confirm",
        name: "OrderConfirm",
        component: () => import("@/views/order/confirm/index.vue"),
        meta: { 
          title: "订单信息填写",
          hideFooter: true,
          hideService: true
        },
      },
      {
        path: "order-search",
        name: "OrderSearch",
        component: () => import("@/views/order/search/index.vue"),
        meta: { 
          title: "订单查询",
          hideFooter: true
        },
      },
      {
        path: "order-success",
        name: "OrderSuccess",
        component: () => import("@/views/order/success/index.vue"),
        meta: { 
          title: "订单提交成功",
          hideFooter: true
        },
      },
      {
        path: "order-list",
        name: "OrderList",
        component: () => import("@/views/order/list/index.vue"),
        meta: { 
          title: "订单查询结果",
          hideFooter: true
        },
      }
    ]
  },
  {
    path: "/article/:id",
    name: 'Article',
    component: () => import("@/views/article/index.vue"),
    meta: { 
      title: "文章",
      hideFooter: true
    },
  },
  //404页面捕获
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound', 
    component: () => import('@/views/404/index.vue'),
    meta: { 
      title: "404",
      hideFooter: true,
      hideHeader: true
    },
  },
];
export const asyncRoutes = [

]
const router = createRouter({
  history: createWebHistory('/web/'),
  routes: constantRoutes as RouteRecordRaw[],
});

function fatteningRoutes(routes: RouteRecord[]): RouteRecord[] {
  return routes.flatMap((route: RouteRecord) => {
    return route.children ? fatteningRoutes(route.children) : route
  })
}

function addRouter(routes: RouteRecord[]) {
  routes.forEach((route: RouteRecord) => {
    if (!router.hasRoute(route.name)) router.addRoute(route as RouteRecordRaw)
    if (route.children) addRouter(route.children)
  })
}

export function resetRouter(routes: RouteRecord[] = constantRoutes) {
  routes.map((route: RouteRecord) => {
    if (route.children) route.children = fatteningRoutes(route.children)
  })
  router.getRoutes().forEach(({ name }) => {
    router.hasRoute(<RouteRecordName>name) &&
      router.removeRoute(<RouteRecordName>name)
  })
  addRouter(routes)
}

export function setupRouter(app: any) {
  addRouter(asyncRoutes)
  setupPermissions(router)
  app.use(router)
  return router
}
export default router;
