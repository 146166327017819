// 主题枚举
export enum THEME {
    '默认',
    '黑金',
    '金色1',
    '金色2',
}

// 订单状态
export enum ORDER_STATUS {
    "待审核",
    "高风险订单待确认" = 5,
    "待客服复审" = 10,
    "待客户确认" = 15,
    "缺货补货中" = 20,
    "已上传物流" = 25,
    "待采购" = 30,
    "采购在途" = 35,
    "待发货" = 40,
    "已签收" = 50,
    "已拒收" = 55,
    "已发起售后申请" = 60,
    "已退货" = 65,
}

// 订单填写位置
export enum ORDER_POSITION {
    "订单页面",
    "商品详情页底部",
    "商品详情页弹窗",
}

// 底部链接
export enum FOOTER_LINK {
    "服务条款" = 10045,
    "隐私政策" = 10046,
    "发货政策" = 10047,
    "退款政策" = 10048,
    "关于我们" = 10049
}


// 统计类型
export enum STATISTIC_TYPE {
    "访客人数",
    "内容浏览人数",
    "加入购物车人数",
    "填写资料人数",
    "下单人数",
}

// 设备类型
// 0安卓1ios2pc3其他
export enum DEVICE_TYPE {
    "安卓",
    "ios",
    "pc",
    "其他",
}
